import React from 'react'
import { Link, graphql } from 'gatsby'
import StringSimilarity from 'string-similarity'
import Layout from '../layouts'
import { SEO } from '../components/seo'
import { Button } from '../components/button'

export default function NotFound(props: any) {
  const { location, data } = props

  const pages = data.allSitePage.nodes.map(({ path }) => path)
  const pathname = location.pathname
  const result = StringSimilarity.findBestMatch(pathname, pages).bestMatch

  function renderContent() {
    return result.rating > 0.7 ? (
      <>
        <p className="mb-5">
          You were probably looking for{' '}
          <Link to={result.target} className="underline text-primary">
            {result.target}
          </Link>
        </p>
        <p className="mb-5">
           Now what are you after? Click your heels together three times and say
          'There's no place like home', press the button below, and you'll be
          there.
        </p>
      </>
    ) : (
      <>
        <p className="mb-5">Yep, you're lost.</p>
        <p className="mb-5">
          Click your heels together three times and say 'There's no place like
          home', press the button below, and you'll be there.
        </p>
      </>
    )
  }

  return (
    <Layout location={location}>
      <div className="text-gray-700 bg-white dark:bg-black dark:text-gray-300">
        <div className="wrapper">
          <div className="container relative flex flex-row flex-wrap py-20 mx-auto mt-20 mb-20">
            <div className="w-full max-w-xl">
              <h1 className="mb-4 text-3xl font-bold">Page not found</h1>
              {renderContent()}

              <div className="mt-5">
                <Link to="/" style={{ textDecoration: 'none' }} className="">
                  <Button
                    className="mr-5"
                    label="Go Back Home"
                    size="medium"
                    variant="primary"
                  />
                </Link>
              </div>
            </div>
            <div className="absolute bottom-0 right-0 flex justify-end w-full">
              <span className="w-full font-bold text-right text-gray-500 text-404 text-7xl text-opacity-5">
                404
              </span>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO />
)

export const pageQuery = graphql`
  {
    allSitePage(
      filter: { path: { nin: ["/dev-404-page", "/404", "/404.html"] } }
    ) {
      nodes {
        path
      }
    }
  }
`
